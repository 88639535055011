// Chakra imports
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Badge from "components/badge/Badge";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
// Custom components
import emailjs from "@emailjs/browser";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsCalendarEvent } from "react-icons/bs";
import { sendData } from "utils/firebase";
import { useHistory } from "react-router-dom";


export default function LongPlanCard(props) {
  const toast = useToast();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [score, setScore] = useState("");
  const [phone, setPhone] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { name, download, badges, link, embed, currentbid, order, isDisabled } =
    props;
  const textColor = useColorModeValue("navy.700", "white");
  const modalColor = useColorModeValue("white", "navy.700");
  const [buttonText, setButtonText] = useState(currentbid);
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const navigate = useHistory();

  useEffect(() => {
    if (name !== "Plan A") {
      let randomNumber = 0;
      const intervalId = setInterval(() => {
        randomNumber = getRandomNumber(
          11,
          randomNumber === 0 ? 35 : randomNumber
        );
        setButtonText(`Only ${randomNumber} Left!`);
        setTimeout(() => {
          setButtonText(currentbid);
        }, 2000);
      }, 4000);
      return () => clearInterval(intervalId);
    }
  }, [name, currentbid]);

  const handleSubmitPlan = (e) => {
    e.preventDefault();
    setLoading(true);
    let uid = uuidv4();
    sendData("plans", uid, {
      id: uid,
      name: userName,
      email: email,
      phone: phone,
      score: score,
      altPhone: altPhone,
      plan: name.slice(-1),
      status: "Initiated",
      comments: "",
    }).then(() => {
      // console.log("Data sent successfully!");

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          {
            subject: "Appointment Booking",
            to_name: userName,
            reply_to: email,
            body: `Thank you for booking an appointment for Plan ${name.slice(
              -1
            )}. We have received your request, and our team is currently reviewing it.\n

Please rest assured that our team will be in touch with you soon to discuss the specifics of your appointment and confirm the details.\n

We appreciate your understanding and patience during this process.`,
          },
          process.env.REACT_APP_EMAIL_KEY
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
          }
        );
      setUserName("");
      setEmail("");
      setScore("");
      setPhone("");
      setAltPhone("");

      setLoading(false);
      onClose();
      toast({
        title: "Appointment Booked.",
        description: "Our team will contact you",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader>
            <Text fontSize="20" fontWeight={300} color="white.600">
              Book an appointment with Accidental Doctor team
            </Text>
          </ModalHeader>
          <ModalCloseButton color="red.400" size="lg" />
          <ModalBody>
            <form
              id="plan"
              onSubmit={(e) => {
                handleSubmitPlan(e);
              }}
            >
              <SimpleGrid columns={1} gap="1rem">
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem">
                  <InputField
                    label="Name"
                    value={userName}
                    setValue={setUserName}
                    type="text"
                    placeholder="John Doe"
                    borderRadius="30px"
                    required
                  />
                  <InputField
                    label="Email"
                    type="email"
                    value={email}
                    setValue={setEmail}
                    placeholder="example@company.com"
                    borderRadius="30px"
                    required
                  />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 3 }} gap="1rem">
                  <InputField
                    label="NEET Score"
                    max={720}
                    value={score}
                    state={score}
                    setValue={setScore}
                    type="number"
                    placeholder="100"
                    borderRadius="30px"
                  />
                  <InputField
                    label="Phone Number"
                    value={phone}
                    setValue={setPhone}
                    type="number"
                    placeholder="+91-XXX-XXX-XXXX"
                    borderRadius="30px"
                    required
                  />
                  <InputField
                    label="Alt. Phone Number"
                    value={altPhone}
                    setValue={setAltPhone}
                    type="number"
                    placeholder="+91-XXX-XXX-XXXX"
                    borderRadius="30px"
                  />
                </SimpleGrid>
              </SimpleGrid>
            </form>
          </ModalBody>

          <ModalFooter>
            <Link target="_blank" href="https://wa.me/7078799044">
              <Button
                leftIcon={<AiOutlineWhatsApp size="1.5rem" />}
                variant="darkBrand"
                color="white"
                fontSize="sm"
                mr="1rem"
                fontWeight="500"
                borderRadius="70px"
                type="submit"
              >
                Help
              </Button>
            </Link>
            <Button
              isLoading={loading}
              variant="darkBrand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              type="submit"
              form="plan"
            >
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card
        p="1rem"
        data-aos={"fade-up"}
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr" }}
          templateRows="1fr"
          gap="1.5rem"
        >
          <Box w="100%" h="100%" order={{ md: order ? 2 : 1, sm: 1 }}>
            <AspectRatio ratio={1.5} w="100%" borderRadius="20px">
              <iframe
                style={{ borderRadius: "20px", width: "100%", height: "100%" }}
                width="100%"
                height="100%"
                src={embed}
                title={name}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </AspectRatio>
          </Box>
          <Flex
            flexDirection="column"
            h="100%"
            order={{ md: order ? 1 : 2, sm: 2 }}
          >
            <Grid templateColumns={{ sm: "1fr", md: "3fr 1fr" }}>
              <Text
                color={textColor}
                fontSize="2xl"
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                What will you get in {name}?
              </Text>

              <Button
                as={Link}
                target="_blank"
                colorScheme="yellow"
                href={download}
                size="sm"
                fontWeight="500"
                borderRadius="70px"
              >
                View Price
              </Button>
            </Grid>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={4} my="1rem">
              {badges.map((badge, index) => (
                <Badge key={index} icon={badge.icon} text={badge.text} onBadgeClick={name !== "Plan A" ? onOpen : () => navigate.replace("/home/plan-a")} />
              ))}
            </SimpleGrid>
            {link && !isDisabled ? (
              <Link
                href={link}
                mt="auto"
              >
                <Button
                  disabled={isDisabled}
                  leftIcon={name !== "Plan A" ? <BsCalendarEvent /> : null}
                  w="100%"
                  variant="darkBrand"
                  color="white"
                  size="lg"
                  fontWeight="500"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                >
                  {buttonText}
                </Button>
              </Link>
            ) : (
              <Button
                mt="auto"
                w="100%"
                leftIcon={name !== "Plan A" ? <BsCalendarEvent /> : null}
                disabled={isDisabled}
                onClick={onOpen}
                variant="darkBrand"
                color="white"
                size="lg"
                fontWeight="500"
                borderRadius="70px"
                px="24px"
                py="5px"
              >
                {buttonText}
              </Button>
            )}
          </Flex>
        </Grid>
      </Card>
    </>
  );
}
