// Chakra imports
import { Text, Box, useColorModeValue, Image, Flex, Button, Badge } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { TbScale } from "react-icons/tb";
import { useHistory } from "react-router-dom";

// Assets
export default function CollegeCard(props) {
  const { title, description, information, image, link, onClick = () => { }, disabled = false } = props;
  // Chakra Color Mode
  const navigate = useHistory();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card onClick={() => navigate.push(link)} mb={{ base: "0px", "2xl": "20px" }}>
      <Box position="relative" width="100%" height="200px">
        {/* Image */}
        <Image
          src={image}
          borderRadius="15px"
          width="100%"
          height="100%"
          objectFit="fill"
        />

        {/* Badge positioned over the image */}
        {information?.Deemed && (
          <Badge
            variant="solid"
            position="absolute"
            top="10px"
            left="10px"
            colorScheme="blue"
            borderRadius="md"
            fontSize="sm"
            fontWeight="bold"
          >
            DEEMED
          </Badge>
        )}
      </Box>

      <Flex w="100%" direction="row">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          noOfLines={2}
          mt="10px"
          mb="4px"
        >
          {title}
        </Text>
      </Flex>
      <Text
        color={textColorSecondary}
        align="justify"
        fontSize="md"
        me="26px"
        mb="40px"
      >
        {description.slice(0, 100) + "..."}
      </Text>

      <Button
        w="100%"
        leftIcon={<TbScale />}
        variant="darkBrand"
        color="white"
        fontWeight="500"
        borderRadius="70px"
        px="24px"
        py="5px"
        mt="auto"
        isDisabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        Compare
      </Button>
    </Card>
  );
}
