// Chakra imports
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

export default function InformationCard(props) {
  const { title, value, primary = false, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bg = useColorModeValue("white", "navy.700");

  // Determine display value for booleans
  const displayValue = typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;

  return (
    <Card
      bg={primary ? "#7551ff" : bg}
      {...rest}
      userSelect="none"
      pointerEvents="none"
    >
      <Box>
        <Text
          fontWeight="500"
          color={primary ? "gray.300" : "gray.400"}
          fontSize="sm"
        >
          {title}
        </Text>
        <Text
          color={primary ? "white" : textColorPrimary}
          fontWeight="500"
          fontSize="md"
        >
          {displayValue}
        </Text>
      </Box>
    </Card>
  );
}
