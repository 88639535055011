/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";
// Chakra imports
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  getDataRealtime,
  getKeysRealtimeInArray,
} from "../../../utils/firebase";
// Custom components
import illustration from "assets/img/auth/auth.png";
import DefaultHome from "layouts/home/Plan";
import StateCard from "../../../components/card/StateCard";
// Assets
import { HiMagnifyingGlass } from "react-icons/hi2";
// Chakra imports

function CollegeMain() {
  // Chakra color mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const [state, setState] = useState([]);
  const [matchedLinks, setMatchedLinks] = useState(null);
  const [search, setSearch] = useState("");

  const [mapLinks, setMapLinks] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDataRealtime("States", "", setMapLinks);
    getKeysRealtimeInArray("colleges", "", setState, setIsLoading);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const matchingIndexes = [];
    state.forEach((item, index) => {
      if (item.toLowerCase().includes(search.toLowerCase())) {
        matchingIndexes.push(index);
      }
    });
    setMatchedLinks(matchingIndexes.map((index) => state[index]));
  };

  return (
    <DefaultHome illustrationBackground={illustration} image={illustration}>
      {isLoading ? (
        <Flex
          w="100%"
          h="100vh"
          direction="column"
          justify={"center"}
          align="center"
        >
          <Spinner color={textColorPrimary} />
        </Flex>
      ) : (
        <>
          <form onSubmit={(e) => handleSearch(e)}>
            <InputGroup
              mt={{ sm: "10vh", md: "25vh" }}
              w={{ sm: "100%", md: "80%" }}
              mx={{ sm: "none", md: "10%" }}
            >
              <InputRightElement
                children={
                  <IconButton
                    m="auto"
                    type="submit"
                    variant="link"
                    icon={<HiMagnifyingGlass color="gray.300" />}
                  />
                }
              />
              <Input
                bg={inputBg}
                fontWeight="500"
                focusBorderColor="teal.400"
                borderRadius="10rem"
                type="text"
                placeholder="Search..."
                value={search}
                color="white"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </form>
          <SimpleGrid
            id="about"
            columns={{ sm: "1", md: "3", lg: "3", xl: "3" }}
            gap="30px"
            my={{ sm: "5vh", md: "25vh" }}
          >
            <StateCard title="Deemed"/>
            {matchedLinks && matchedLinks.length !== 0 && search.length !== 0
              ? matchedLinks.map((state, index) => (
                  <StateCard key={index} title={state} mapLinks={mapLinks} />
                ))
              : state.map((state, index) => (
                  <StateCard key={index} title={state} mapLinks={mapLinks} />
                ))}
          </SimpleGrid>
        </>
      )}
    </DefaultHome>
  );
}

export default CollegeMain;
