import {
  Button,
  Flex,
  Modal,
  ModalBody,
  Tooltip,
  Link,
  IconButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { useEffect, useState } from "react";
import InputField from "../fields/InputField"; // Assuming InputField is a custom component
import { sendData } from "utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { FaInstagram } from "react-icons/fa";

const LetUsKnowFormModal = () => {
  const toast = useToast();
  const modalColor = useColorModeValue("secondaryGray.300", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      onOpen();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let uid = uuidv4();
    sendData("contact", uid, {
      id: uid,
      name: name,
      phone: phone,
      msg: "", // Default empty message
    }).then(() => {
      setName("");
      setPhone("");
      onClose();
      toast({
        title: "Thank you!",
        description: "We've received your details and will be in touch.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader><Flex alignItems="center" justifyContent="space-between" width="100%">
      <Text>Stay Updated with Important Dates!</Text>
      <Tooltip label="Instagram">
        <IconButton
          as={Link}
          target="_blank"
          href="https://ig.me/m/accidentaldoctor"
          aria-label="Instagram"
          icon={<FaInstagram />}
          size="lg"
          variant="ghost"
        />
      </Tooltip>
    </Flex>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Flex w="100%" direction="column">
                <InputField
                  label="Name"
                  type="text"
                  value={name}
                  setValue={setName}
                  placeholder="John Doe"
                  borderRadius="30px"
                  required
                />
                <InputField
                  label="Phone Number"
                  type="number"
                  value={phone}
                  setValue={setPhone}
                  placeholder="+91-XXX-XXX-XXXX"
                  borderRadius="30px"
                  required
                />
              </Flex>
              <ModalFooter>
                <Button
                  rightIcon={<FiSend />}
                  w="100%"
                  variant="darkBrand"
                  color="white"
                  size="lg"
                  fontWeight="500"
                  borderRadius="70px"
                  type="submit"
                >
                  Submit
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LetUsKnowFormModal;
