import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  equalTo,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  serverTimestamp,
  set,
  update,
} from "firebase/database";
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query as queryFirestore,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, realtime } from "./../variables/firebaseConfigs";

export const deleteDataField = async (collection, document) => {
  // console.log(collection,document)
  // await updateDoc(doc(db, collection, document), {
  //   : deleteField()
  // })
};

export const deleteData = async (collection, document) => {
  await deleteDoc(doc(db, collection, document));
};

export const deleteDataRealtime = async (collection) => {
  await remove(ref(realtime, collection));
};
export const countDocuments = async (col, cond = null) => {
  let reference;
  if (cond) reference = queryFirestore(collection(db, col), where(...cond));
  else reference = queryFirestore(collection(db, col));
  const docSnap = await getCountFromServer(reference);
  return docSnap.data().count;
};
function convertSub(sub) {
  let res = [];
  Object.keys(sub).forEach((key) => {
    if (sub[key].hasOwnProperty("sub")) sub[key].sub = convertSub(sub[key].sub);
    res.unshift(sub[key]);
  });
  // console.log(res)
  return res;
}
function jamToArray(snapshot) {
  const returnArr = [];
  snapshot.forEach(function (childSnapshot) {
    const item = childSnapshot.val();
    // console.log(item)
    if (item.hasOwnProperty("sub")) item.sub = convertSub(item.sub);
    returnArr.unshift(item);
  });
  return returnArr;
}

export const getMenuProducts = async (path, collection, document) => {
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("category/path"),
    equalTo(path)
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    // console.log(data)
    if (data) {
      return data;
    }
  });
};

export const sendDataRealtime = async (collection, document, data) => {
  data.datentime = serverTimestamp();
  await set(ref(realtime, collection + "/" + document), data);
};

export const addDataRealtime = async (collection, data) => {
  await push(ref(realtime, collection), data);
};

export const updateDataRealtime = async (collection, data) => {
  data.datentime = serverTimestamp();
  // console.log(data)
  await update(ref(realtime, collection), data);
};

export const getDataRealtime = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  // console.log(collection+'/'+document)
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime")
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = snapshot.val();
    // console.log(data);
    if (data) {
      func(data);
      load(false);
    }
  });
  return data;
};

const getCategories = (categoryList, prefix) => {
  let resArray = [];
  categoryList.forEach((element) => {
    element.name = prefix + element.name;
    if (element.hasOwnProperty("sub")) {
      resArray.push(...getCategories(element.sub, element.name + " > "));
    } else resArray.push(element);
  });
  return resArray;
};

export const getCatDataRealtimeInArray = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime")
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    func(getCategories(data, ""));
    load(false);
  });
  return data;
};

export const getDeemedColleges = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  console.log(`Fetching deemed colleges from: ${collection}/${document}`);

  const reference = query(
    ref(realtime, `${collection}/${document}`),
    orderByChild("datentime")
  );

  let colleges = [];

  await onValue(reference, (snapshot) => {
    let collegeData = snapshot.val();
    if (!collegeData) return;

    delete collegeData["datentime"];
    delete collegeData["isActive"];

    let allColleges = [];

    // Handling additional parent keys dynamically
    Object.entries(collegeData).forEach(([state, innerData]) => {
      Object.values(innerData).forEach((college) => {
        allColleges.push({
          ...college,
          state, // Adding state key
        });
      });
    });

    // Filter colleges where `information.Deemed` is `true`
    let deemedColleges = allColleges.filter(
      (college) => college.information?.Deemed === true
    );

    func(deemedColleges);
    load(false);
    colleges = deemedColleges;
  });

  return colleges;
};


export const getDataRealtimeInArray = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  console.log(collection + "/" + document);
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime")
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    let college = snapshot.val()
    delete college['datentime'];
    delete college['isActive'];
    console.log(college)
    data = Object.values(college);
    data = func(data);
    load(false);
  });
  return data;
};

export const getKeysRealtimeInArray = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  console.log(collection + "/" + document);
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime")
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = Object.keys(snapshot.val());
    data = func(data);
    load(false);
  });
  return data;
};
export const getKeysRealtimeInArrayWithFlag = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  }
) => {
  console.log(collection + "/" + document);
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime")
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = [Object.keys(snapshot.val()),snapshot.val()];
    data = func(data);
    load(false);
  });
  return data;
};
export const getMenuDataRealtimeInArray = async (
  collection,
  document,
  path,
  func,
  load = (e) => {
    return 0;
  }
) => {
  // console.log(collection,document,path)
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("category/path"),
    equalTo(path)
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    console.log(data);
    if (data) {
      func(data);
      load(false);
    }
  });
};
export const sendData = async (collection, document, data) => {
  data.datentime = Date.now();
  return await setDoc(doc(db, collection, document), data);
};

export const updateData = async (collection, document, data) => {
  data.datentime = Date.now();
  await updateDoc(doc(db, collection, document), data);
};

export const getCollection = async (col, onChange = () => {}, cond = null) => {
  let reference;
  console.log(...cond);
  if (cond === null)
    reference = queryFirestore(
      collection(db, col),
      orderBy("datentime", "desc")
    );
  else
    reference = queryFirestore(
      collection(db, col),
      orderBy("datentime", "desc"),
      where(...cond)
    );
  const querySnapshot = await getDocs(reference);
  let data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  onChange(data);
  return data;
};

export const getDocument = async (collection, document) => {
  const docSnap = await getDoc(doc(db, collection, document));
  return docSnap.data();
};

export const getDocumentRealtime = async (collection, document, func) => {
  await onSnapshot(doc(db, collection, document), (doc) => {
    func(doc.data());
  });
};

export var currUser =
  typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : global.user;
export const setCurrentShop = (shop) => {
  global.currShop = shop;
  return null;
};
export const getCurrentShop = () => {
  return global.currShop;
};
export const loginUser = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logoutUser = async () => {
  await signOut(auth);
  localStorage.clear();
};

export const createLogin = async (
  name,
  email,
  password,
  phone,
  shop,
  status,
  description,
  role
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      let user = userCredential.user;
      console.log(
        sendData("users", user.uid, {
          id: user.uid,
          name: name,
          email: email,
          shop: shop,
          phone: phone,
          status: status,
          description: description,
          role: role,
        })
      );
    })
    .catch((error) => {
      console.log(error.message);
      return error.message;
    });
};

export const getCurrentUser = async (
  func = (e) => {
    return 0;
  }
) => {
  await onAuthStateChanged(auth, (user) => {
    if (user) {
      getDocument("users", user.uid).then(
        function (value) {
          value.currShop = value.role === "Author" ? value.shop[0] : value.shop;
          func(value);
          currUser = value;
        },
        function (error) {
          //   setError(error);
        }
      );
      return user.email;
    } else {
      func(null);
      return "User not found";
    }
  });
};
