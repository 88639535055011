/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";
// Chakra imports
import { Flex, SimpleGrid, Spinner, useColorModeValue } from "@chakra-ui/react";
import Compare from "components/compare/Compare";
import FooterAd from "components/footer/FooterAd";
import { getDataRealtimeInArray, getDeemedColleges } from "../../../utils/firebase";
// Custom components
import DefaultHome from "layouts/home/Plan";
import CollegeCard from "../../../components/card/CollegeCard";
// Assets
import illustration from "assets/img/auth/auth.png";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
function Colleges() {
  const textColorPrimary = useColorModeValue("white.400", "white");
  // Chakra color mode
  let { state } = useParams();
  const [college, setCollege] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let isKey = useSelector((state) => state.isKey);
  useEffect(() => {
    if (state == "Deemed") {
      getDeemedColleges(`colleges`, "", setCollege, setIsLoading);
    }
    else {
      getDataRealtimeInArray(`colleges/${state}`, "", setCollege, setIsLoading);
    }
  }, []);

  return (
    <DefaultHome illustrationBackground={illustration} image={illustration}>
      {isLoading ? (
        <Flex
          w="100%"
          h="100vh"
          direction="column"
          justify={"center"}
          align="center"
        >
          <Spinner color={textColorPrimary} />
        </Flex>
      ) : (
        <>
          <Compare items={items} setItems={setItems} isActive />
          <SimpleGrid
            columns={{ sm: "1", md: "3", lg: "3", xl: "3" }}
            gap="20px"
            mt={{ sm: "10vh", md: "20vh" }}
          >
            {college.map((college, index) => (
              <CollegeCard
                key={index}
                {...college}
                link={`/home/college/${state == "Deemed" ? college?.state : state}/${college.id}`}
                onClick={() => setItems((prev) => [...prev.slice(-2), college])}
                disabled={items.includes(college)}
              />
            ))}
          </SimpleGrid>
          {!isKey && <FooterAd />}
        </>
      )}
    </DefaultHome>
  );
}

export default Colleges;
