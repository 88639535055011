/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
// Chakra imports

import {
  Box,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import FooterAd from "components/footer/FooterAd";
import { getDataRealtime } from "../../../utils/firebase";

import {FaStar, FaRegStar, FaStarHalfAlt} from "react-icons/fa";

// Custom components
import DefaultHome from "layouts/home/Plan";
// Assets
import illustration from "assets/img/auth/auth.png";
import InformationCard from "components/card/InformationCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


function handleKeyDown(event) {
  if (
    event.keyCode === 123 ||
    (event.ctrlKey && event.shiftKey && event.keyCode === 73)
  ) {
    event.preventDefault();
  }
}
function CollegeDetail() {
  
function handleContextMenu(event) {
  event.preventDefault();
}
// Chakra color mode
let { state, id } = useParams();
const [isLoading, setIsLoading] = useState(true);
  let internal = useSelector((state) => state.internal);
  console.log(internal);
  const [college, setCollege] = useState({});
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    getDataRealtime(`colleges/${state}/${id}`, "", setCollege, setIsLoading);
  }, [state, id]);
  return (
    <DefaultHome illustrationBackground={illustration} image={illustration}>
      {isLoading ? (
        <Flex
          w="100%"
          h="100vh"
          direction="column"
          justify={"center"}
          align="center"
        >
          <Spinner color={textColorPrimary} />
        </Flex>
      ) : (
        <>
          <div onContextMenu={handleContextMenu}>
            <Box
              mt={{ sm: "3rem", md: "7rem", lg: "7rem" }}
              mb="1rem"
              width="100%"
              height={{ sm: "30vh", md: "65vh" }}
            >{
              college.image && college?.yt?.startsWith("https://www.youtube.com") ? (
                <iframe
                  style={{ borderRadius: "20px", width: "100%", height: "100%" }}
                  width="100%"
                  src={`${college.yt}?controls=0&rel=0`}
                  title={name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              ) : (
                <Image
                  src={college.image}
                  borderRadius="15px"
                  width="100%"
                  maxHeight="100%"
                  objectFit="fill"
                />
              )
            }
            
            </Box>
            <Flex w="100%"  direction={{ base: "column", md: "row" }}>
              <Text
                color={textColorPrimary}
                fontWeight="bold"
                fontSize="3xl"
                mt="10px"
                mb="4px"
              >
                {college.title}
              </Text>
              
              {college.rating && <Flex ml="auto" my="1rem" align="center">
                <ReactStars
                  count={5}
                  value={college.rating || 0}
                  size={16} // Reduce size to fit inside the badge
                  edit={false}
                  half={true}
                  p={0}
                  m={0}
                  emptyIcon={<FaRegStar color="white" />}
                  filledIcon={<FaStar color="white" />}
                />
              </Flex>}
            </Flex>
            <Box>
              <SimpleGrid
                columns={{ sm: "1", md: "4", lg: "4" }}
                gap="20px"
                py="1rem"
              >
                {college.hasOwnProperty("information") &&
                  Object.keys(college.information).map((info, index) => (
                    <InformationCard
                      key={index}
                      boxShadow={cardShadow}
                      title={info}
                      value={college.information[info]}
                    />
                  ))}
              </SimpleGrid>
              {internal &&
                Object.keys(internal).length > 0 &&
                internal.hasOwnProperty(id) && (
                  <>
                    <Divider mb="1rem" />
                    <Text
                      color="#7551ff"
                      fontSize="lg"
                      fontWeight="bold"
                      textAlign="center"
                      casing="uppercase"
                    >
                      Internal Details
                    </Text>
                    <SimpleGrid
                      columns={{ sm: "1", md: "4", lg: "4" }}
                      gap="20px"
                      py="1rem"
                    >
                      {internal[id]?.info.map(({ title, value }, index) => (
                        <InformationCard
                          key={index}
                          boxShadow={cardShadow}
                          title={title}
                          value={value}
                          primary
                        />
                      ))}
                    </SimpleGrid>
                  </>
                )}
              <Divider mb="1rem" />
              <Text
                color={textColorSecondary}
                fontSize="md"
                me="26px"
                mb="40px"
              >
                {college.description}
              </Text>
            </Box>
            {(Object.keys(internal).length === 0 ||
              !internal.hasOwnProperty(id)) && <FooterAd />}
          </div>
        </>
      )}
    </DefaultHome>
  );
}

export default CollegeDetail;
