import { Flex, Text, Icon, useColorModeValue } from "@chakra-ui/react";

const Badge = ({ icon, text, onBadgeClick = () => {} }) => {
  const bgColor = useColorModeValue("white", "navy.700");
  const boxShadow = useColorModeValue(
    "3px 3px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.5)",
    "3px 3px 12px rgba(0, 0, 0, 0.4), -6px -6px 12px rgba(32, 56, 100, 0.8)"
  );
  const textColor = useColorModeValue("black", "white");

  return (
    <Flex
      align="center"
      color={textColor}
      px={4}
      py={2}
      borderRadius="1rem"
      gap={2}
      bg={bgColor}
      boxShadow={boxShadow}
      onClick={onBadgeClick}
    >
      <Icon as={icon} width="20px" height="20px" color="inherit" />
      <Text>{text}</Text>
    </Flex>
  );
};

export default Badge;
